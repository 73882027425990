<template>
<v-row>
  <v-col cols="12" md="12">
    <strong>Tipo da Fatura</strong>
    <v-divider/>
    <v-radio-group v-model="value.descTipo" row :disabled="parseInt(value.id) > 0">
      <v-radio
        label="Mensalidade"
        value="MA"
      ></v-radio>
      <v-radio
        label="Matrícula"
        value="MT"
      ></v-radio>
      <v-radio
        label="Locação"
        value="AL"
      ></v-radio>
      <v-radio
        label="Competição"
        value="IC"
      ></v-radio>
      <v-radio
        label="Fatura Avulsa"
        value="AV"
      ></v-radio>
    </v-radio-group>
  </v-col>

  <v-col cols="12" md="12" v-if="value.descTipo==='AV'">
    <strong>Cliente da Casa ou Avuslo</strong>
    <v-divider/>
    <v-radio-group v-model="value.tipoCliente" row :disabled="parseInt(value.id) > 0">
      <v-radio
        label="da Casa"
        value="DC"
      ></v-radio>
      <v-radio
        label="Avulso"
        value="AV"
      ></v-radio>
    </v-radio-group>
  </v-col>
  <v-col cols="12" sm="12" v-show="((typeof value.id) === 'undefined' && value.tipoCliente==='DC') || value.descTipo!=='AV'">
    <search-box
          :emit-all-properties="true"
          :disabled="value.id > 0"
          label="* Cliente"
          popup-label="Selecione um Cliente"
          :id.sync="value.idCliente"
          :nome.sync="value.nome_idCliente"
          :hidden-clear="false"
          :value="cliente"
          :loader-fn="loaderClientes"
          item-key="id"
          item-text="nome"
          >
        <template v-slot:empty>
          <p class="ma-2">
            Nenhum Cliente encontrado
          </p>
        </template>
      </search-box>
  </v-col>
  <v-col cols="12" v-show="(typeof value.id) === 'undefined' && value.tipoCliente==='AV' && value.descTipo==='AV'">
    <v-text-field label="* Cliente Avulso" dense type="text"  v-model.trim="value.clienteAvulso" />
  </v-col>
  <v-col cols="12" sm="12" v-show="parseInt(value.id) > 0">
    <strong>Cliente</strong>: {{value.nome_idCliente}}
  </v-col>

  <v-row v-if="value.descTipo==='IC'" v-show="(typeof value.id) === 'undefined'">
    <v-col cols="12" sm="12" class="pa-6">
      <v-autocomplete
          v-model="value.idInscricao"
          dense
          label="* Inscrições"
          :items="inscricoes"
          item-text="descricao"
          item-value="id"
          return-value
          no-data-text="Nenhuma Inscrição encontrada"
          :clearable="true"
        >
      </v-autocomplete>
    </v-col>
  </v-row>

  <v-row v-if="value.descTipo==='MA' || value.descTipo==='MT'" v-show="(typeof value.id) === 'undefined'">
    <v-col cols="12" sm="12" class="pa-6">
      <v-autocomplete
          v-model="value.idMatricula"
          dense
          label="* Matrículas Ativas"
          :items="matriculasAtivas"
          item-text="descricao"
          item-value="id"
          return-value
          no-data-text="Nenhuma Matrícula encontrada"
          :clearable="true"
        ></v-autocomplete>
    </v-col>    
  </v-row>   
  
  <v-row v-if="value.descTipo==='AL'" v-show="(typeof value.id) === 'undefined'">
    <v-col cols="12" sm="12" class="pa-6">
      <v-autocomplete
          v-model="value.idAluguel"
          dense
          label="* Locações Ativas"
          :items="alugueisAtivos"
          item-text="descricao"
          item-value="id"
          return-value
          no-data-text="Nenhuma Locação Mensal encontrada"
          :clearable="true"
        ></v-autocomplete>
    </v-col>      
  </v-row> 

  <v-col cols="12" sm="12">
      <v-text-field dense
                    label="* Descrição"
                    :rules="[
                      this.$validators.number.required,
                    ]"
                    v-model="value.descricao" type="text"/>
  </v-col>
  <v-col cols="12" md="4">
       <v-text-field label="* Mês/Ano"
                    dense
                    type="month"
                    v-model="value.mesRef"
                    :rules="[
                        this.$validators.string.required,
                  ]"/>
  </v-col>
  <v-col cols="12" sm="4">
      <v-text-field label="* Data Vencimento"
                    dense
                    type="date"
                    v-model="value.dtVenc"
                    :rules="[
                        this.$validators.string.required,
                  ]"/>
  </v-col>
  <v-col cols="12" sm="4">
    <v-text-field dense
        label="* Valor"
        prefix="R$"
        :rules="[
            this.$validators.number.required,
            v => this.$validators.number.greaterThanOrEquals(v, 0)
        ]"
        v-model="value.valor" type="number"/>
  </v-col>
  
  
</v-row>
</template>

<script>
import SearchBox from "../common/SearchBox";
import {findAll as findAllClientes} from "../../../api/clientes";
import {findAllInscricoesAtivas} from "../../../api/inscricoes";
import {findAllMatriculasAtivas} from  "../../../api/matriculas";
import {findAllAluguelMensalAtivos} from "../../../api/aluguel";
import LookupDominio from "../common/LookupDominio";
import {ItemDeDominio} from "../../../api/itemsDominio";
import moment from 'moment';

export default {
  name: "FaturasForm",
  components: {LookupDominio, SearchBox},
  props: {
    value: {
      type: Object
    }
  },
  data () {
    return {
      matriculasAtivas: [],
      alugueisAtivos: [],
      inscricoes: [],
      exibeComboCliente: false,
      exibeInputCliAvulso: false,
      lookups: {
        status: ItemDeDominio.STATUS_PADRAO
      },
    }
  },
  computed: {
    selecionouClienteAvulso() {
      return this.value.clienteAvulso != null && this.value.clienteAvulso.trim() !== '';
    },
    cliente() {
      if(this.value.idCliente == null) return null;
      return {
        id: this.value.idCliente,
        nome: this.value.nome_idCliente
      }
    },
  },
  methods: {
    controlaClienteCadastroEdicao(){
      if (this.value.descTipo === 'AV'){
        this.exibeComboCliente = true;
        this.exibeInputCliAvulso = true;
      } else {
        this.exibeComboCliente = true;
        this.exibeInputCliAvulso = false;
      }
    },  
    mesRefAtual() {
      console.log("mesRef: ",this.value.mesRef);
      if (typeof this.value.mesRef === "undefined" || this.value.mesRef === null || this.value.mesRef === ""){
        this.value.mesRef = moment().format('yyyy-MM');
      } else {
        let mes = this.value.mesRef.substr(0,2)
        let ano = this.value.mesRef.substr(3,4);
        this.value.mesRef = moment(ano+"-"+mes+"-"+"01").format('yyyy-MM');
      }
    },
    loaderClientes(page, search) {
      return findAllClientes(page, {
        nome: search
      })
    },
    async loaderMatriculasAtivas() {
        const page = await findAllMatriculasAtivas(1, this.value);
        this.matriculasAtivas = page.elements;
    },
    async loaderAluguelMensalAtivos() {
        const page = await findAllAluguelMensalAtivos(1, this.value);
        this.alugueisAtivos = page.elements;
    },
    async loaderInscricoes() {
        const page = await findAllInscricoesAtivas(1, this.value);
        this.inscricoes = page.elements;
    },
    anoPermitidoInicio() {
        return moment().format('YYYY');
    }, 
    anoPermitidoFim() {
        return parseInt(moment().format('YYYY'))+2;
    },     
  },
  mounted() {
    this.mesRefAtual();
    this.loaderMatriculasAtivas();
    this.loaderAluguelMensalAtivos();
    this.loaderInscricoes();
  },
  watch: {
    'value.idCliente':{
      handler(newValue, oldValue) {
        this.loaderMatriculasAtivas();
        this.loaderAluguelMensalAtivos();
        this.loaderInscricoes();
      }
    },
  },
  created() {
  },
}
</script>

<style scoped>

</style>