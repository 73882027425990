<template>
  <base-view
      @update:search-values="updateSearch"
      :search-columns="searchColumns">
    <template v-slot:actions:extras>
      <v-btn @click="gerarArquivo" color="primary" :outlined="$vuetify.breakpoint.mobile" :width="'200px'"
             :text="!$vuetify.breakpoint.mobile"> Gerar Arquivo
      </v-btn>
    </template>
    <v-dialog :fullscreen="$vuetify.breakpoint.mobile"
          max-width="700px"
          persistent v-model="mostraErros">
      <v-card class="d-flex flex-column">
        <v-card-title>
          Retorno Geração Arquivo Remessa
          <v-spacer/>
          <v-btn icon @click="mostraErros = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider/>
        <v-card-text class="py-4 flex-grow-1">
          <strong>Faturas selecionadas:</strong> {{qtdFaturasSelecionadas}}<br>
          <strong>Mensagem:</strong> {{retornoGeracao.msg}} <br>
          <div v-if="retornoGeracao.arquivoRemessa !== ''">
            <strong>Arquivo:</strong> <a target="_blank" :href="retornoGeracao.arquivoRemessa">Ver arquivo</a>
          </div>
          <div v-if="retornoGeracao.inconsistencias.length > 0">
            <strong>Inconsistências:</strong> 
            <ul>
              <li v-for="inconsistencia of retornoGeracao.inconsistencias" :key="inconsistencia.id">
                {{inconsistencia.mensagem}}
              </li>
            </ul>
          </div>
        </v-card-text>
        <v-divider/>
        <v-card-actions>
          <v-spacer/>
          <v-btn outlined color="primary" @click="mostraErros = false">Fechar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <div>
      <v-progress-linear
        indeterminate
        color="cyan"
        v-show="loading"
      ></v-progress-linear>
      <faturas-para-remessa-data-table ref="dataTable"
                         :selection.sync="selection"
                         :search-values="searchValues"/>
    </div>
  </base-view>

</template>

<script>
import FaturasParaRemessaDataTable from "../../../components/shared/datatables/FaturasParaRemessaDataTable";
import BaseView from "../../BaseView";
import {gerarArquivoRemessaBanco} from "../../../api/faturas";
import CreateEditFaturasDialog from "../../../components/shared/dialogs/CreateEditFaturasDialog";
import {findAll as findAllCliente} from '../../../api/clientes';
import {findAll as findAllEventos} from '../../../api/eventos';
import {findAll as findAllLocal} from '../../../api/locais';
import {ItemDeDominio} from "../../../api/itemsDominio";
import {mapActions} from "vuex";
import {ApiError} from "../../../api/core";
import * as moment from 'moment';

export default {
  name: "index",
  components: {CreateEditFaturasDialog, BaseView, FaturasParaRemessaDataTable},
  data() {
    return {
      selection: [],
      qtdFaturasSelecionadas: 0,
      retornoGeracao: {
        msg: "",
        arquivoRemessa: "",
        inconsistencias: []
      },
      mostraErros: false,
      loading: false,
      item: null,
      editDialogOpened: false,
      selectedItems: [],
      searchValues: {},
      searchColumns: [
        {
          name: 'Cliente',
          column: 'idCliente',
          type: 'autocomplete',
          optionsFactory: (search) => findAllCliente(1, {
            nome: search
          })
              .then(r => {
                return r.elements.map(e => ({
                  key: e.id,
                  valor: e.nome
                }))
              })

        },
        {
          group: 'Período',
          columns: [
            {
              name: 'De',
              column: 'dtInicio',
              type: 'date',
            },
            {
              name: 'Até',
              column: 'dtFim',
              type: 'date',
            },
          ]
        },
        {
          name: 'Descrição',
          column: 'descricao',
          type: 'text'
        },
        {
          name: 'Nosso Número',
          column: 'id',
          type: 'number'
        },
        {
          name: 'Tipo Plano',
          column: 'tipoPlano',
          type: 'select',
          optionsFactory: () => this.fetch({
            itemDominio: ItemDeDominio.TIPO_PLANO
          })
        },
        {
          name: 'Local',
          column: 'idLocal',
          type: 'autocomplete',
          optionsFactory: (search) => findAllLocal(1,{
            descricao: search
          })
              .then(r => {
                return r.elements.map(e => ({
                  key: e.id,
                  valor: e.descricao
                }))
              })

        },
        {
          name: 'Competição',
          column: 'idEvento',
          type: 'autocomplete',
          optionsFactory: (search) => findAllEventos(1, {
            nome: search
          })
              .then(r => {
                return r.elements.map(e => ({
                  key: e.id,
                  valor: e.descricao
                }))
              })
        },
        {
          name: 'Tipo Fatura',
          column: 'tipoFatura',
          type: 'select',
          optionsFactory: () => this.fetch({
            itemDominio: ItemDeDominio.ARR_TIPO_FATURA
          })
        },
      ],
    }
  },
  methods: {
    ...mapActions('itemsDominio', ['fetch']),
    updateSearch(search) {
      this.searchValues = search;
    },
    async gerarArquivo() {
      try {
        this.loading = true;
        let linhasSelecionadas = this.selection.map(s => s.id).join(",");
        this.qtdFaturasSelecionadas = this.selection.length;
        if (linhasSelecionadas!==""){
          this.retornoGeracao = await gerarArquivoRemessaBanco(linhasSelecionadas);
          this.mostraErros = true;
          this.$refs.dataTable.refresh();
        } else {
          this.$toast.error(`Selecione as faturas que entrarão no arquivo de remessa`, {
            timeout: 6000
          })
        }
      } catch (err) {
        if(err instanceof ApiError) {
          this.$toast.error(err.message, {
            timeout: 6000
          })
        } else {
          this.$toast.error(`Não foi possível gerar o arquivo.`, {
            timeout: 6000
          })
        }
      } finally {
        this.loading = false;
      }
    }
  },
  computed: {

  }
}
</script>

<style scoped lang="scss">

::v-deep {
  .v-data-table__wrapper {
    height: calc(100vh - 157px);
  }
}

</style>